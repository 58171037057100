<template>
  <div class="page-home">
    <Hplc />
    <Navigation />
  </div>
</template>

<script>
  import Hplc from '@/prerender-pages/new/components/home/Hplc';
  import Navigation from '@/prerender-pages/new/components/home/navigation/Navigation';

  export default {
    name: 'PageHome',

    components: {
      Navigation,
      Hplc,
    },
  };
</script>
