<template>
  <a :href="link" class="section">
    <div class="section__title">{{ title }}</div>
    <div class="section__description">{{ description }}</div>
    <div class="section__icon">
      <svg
        width="63"
        height="26"
        viewBox="0 0 63 26"
        xmlns="http://www.w3.org/2000/svg"
        class="section__icon-arrow"
      >
        <path d="M47.2894 9.75H0V16.25H47.2894V26L63 13L47.2894 0V9.75Z" />
      </svg>
    </div>
  </a>
</template>

<script>
  export default {
    name: 'Section',

    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .section {
    display: flex;
    flex-direction: column;
    color: #006dec;

    &:hover {
      text-decoration: underline;
      color: darken(#006dec, 4%);

      .section__icon-arrow {
        fill: darken(#006dec, 10%);
      }
    }

    &:active {
      text-decoration: underline;
      color: transparentize(#006dec, 0.2);

      .section__icon-arrow {
        fill: transparentize(#006dec, 0.2);
      }
    }

    &__title {
      font-size: 4.8rem;
      font-weight: $weight-semi-bold;
      margin-bottom: 24px;
      letter-spacing: -0.05em;
    }

    &__description {
      font-size: 2.1rem;
      line-height: 1.52;
      letter-spacing: 0.01em;
      margin-bottom: 40px;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 20px;
      }
    }

    &__icon {
      margin-top: auto;
    }

    &__icon-arrow {
      fill: #006dec;
      transition: fill 0.1s;
    }
  }
</style>
