<template>
  <div class="hplc">
    <ContentContainer class="hplc__container"
      ><img
        src="@/prerender-pages/new/assets/images/layout/hplc-cloud-logo@6x.png"
        alt="hplc logo"
        class="hplc__logo"
      />
      <h1 class="hplc__title">Chemical Analysis now Online</h1>
      <h2 class="hplc__subtitle">
        HPLC Cloud - is a web application for <br />
        High Performance Liquid Chromatography.
      </h2>
      <BtnEnter class="hplc__btn-enter" />
    </ContentContainer>
  </div>
</template>

<script>
  import BtnEnter from '@/prerender-pages/new/components/shared/btnEnter/BtnEnter';
  import ContentContainer from '@/prerender-pages/new/components/shared/ContentContainer';

  export default {
    name: 'Hplc',

    components: {
      ContentContainer,
      BtnEnter,
    },
  };
</script>

<style lang="scss" scoped>
  .hplc {
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(138.59% 200.63% at 77.78% -4.64%, #fffbda 0%, #ffd5bd 100%), #ffedd0;
    height: 1000px;
    padding: 40px 0;

    @media (max-width: $screen-md-max) {
      height: 700px;
    }

    @media (max-width: $screen-sm-max) {
      height: 600px;
    }

    @media (max-width: $screen-xs-max) {
      height: auto;
      padding-top: 60px;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &__logo {
      margin-bottom: 60px;
      height: 150px;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 30px;
        height: 110px;
      }
    }

    &__title {
      text-align: center;
      font-size: 6.4rem;
      background: linear-gradient(
        to left,
        #ff259b -16.13%,
        #ff259b 45%,
        #11129d 105%,
        #11129d 133.01%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 0 26px;
      font-weight: $weight-extra-bold;

      @media (max-width: $screen-sm-max) {
        font-size: 4rem;
      }
    }

    &__subtitle {
      text-align: center;
      font-size: 2.6rem;
      color: rgba(149, 71, 0, 0.59);
      margin-bottom: 64px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 40px;
      }
    }

    &__btn-enter {
      width: 250px;
      height: 40px;
    }
  }
</style>
