<template>
  <div class="navigation">
    <ContentContainer>
      <div class="navigation__sections">
        <Section
          v-for="{ title, description, link } of sections"
          :key="title"
          v-bind="{ title, description, link }"
        />
      </div>
    </ContentContainer>
  </div>
</template>

<script>
  import ContentContainer from '@/prerender-pages/new/components/shared/ContentContainer';
  import Section from '@/prerender-pages/new/components/home/navigation/private/Section';

  const sections = [
    {
      title: 'About the Cloud',
      description:
        'The web-application www.hplc.cloud solves the problem of chromatography instrument control and data handling. Run injections and sequences, record chromatograms, integrate peaks and estimate compounds.',
      link: 'https://newcrom.com/software',
    },
    {
      title: 'Alltesta Hardware',
      description:
        'User friendly, cost effective and flexible solution for chromatography. Autosampler makes automation available. Two syringe pumps privide great gradient solvent delivery. UV-Vis detection. Valve to implement column switch and wash capabilities.',
      link: 'https://newcrom.com/alltesta',
    },
  ];

  export default {
    name: 'Navigation',

    components: { Section, ContentContainer },

    data: () => ({
      sections,
    }),
  };
</script>

<style lang="scss" scoped>
  .navigation {
    display: flex;
    align-items: center;
    height: 596px;
    background-color: #ededed;

    @media (max-width: $screen-md-max) {
      height: auto;
      padding: 40px 0;
    }

    &__sections {
      display: grid;
      justify-content: center;
      grid-template-columns: 375px 440px;
      gap: 100px;

      @media (max-width: $screen-md-max) {
        gap: 30px;
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $screen-sm-max) {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
